import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

export default function Yaralanma() {
  const [name, setName] = useState();
  const [errorField, SetErrorField] = useState("");
  const [success, SetSuccess] = useState(false);
  const [kazaturu, setKazaTuru] = useState("Trafik Kazası");
  const [kazatarihi, setKazaTarihi] = useState();
  const [kusurdurumu, setKusurDurumu] = useState();
  const [maluliyetdurumu, setMaluliyetDurumu] = useState();
  const [dogumyili, setDogumYili] = useState();
  const [cinsiyet, setCinsiyet] = useState();
  const [gelir, setGelir] = useState();
  const [telno, setTelNo] = useState();
  const [rawNumber, setRawNumber] = useState("");
  const [finish, setFinish] = useState(false);
  const [il, setIl] = useState();
  const [isLoading, setLoading] = useState(false);
  const cities = [
    "Adana",
    "Adıyaman",
    "Afyonkarahisar",
    "Ağrı",
    "Aksaray",
    "Amasya",
    "Ankara",
    "Antalya",
    "Ardahan",
    "Artvin",
    "Aydın",
    "Balıkesir",
    "Bartın",
    "Batman",
    "Bayburt",
    "Bilecik",
    "Bingöl",
    "Bitlis",
    "Bolu",
    "Burdur",
    "Bursa",
    "Çanakkale",
    "Çankırı",
    "Çorum",
    "Denizli",
    "Diyarbakır",
    "Düzce",
    "Edirne",
    "Elazığ",
    "Erzincan",
    "Erzurum",
    "Eskişehir",
    "Gaziantep",
    "Giresun",
    "Gümüşhane",
    "Hakkâri",
    "Hatay",
    "Iğdır",
    "Isparta",
    "İstanbul",
    "İzmir",
    "Kahramanmaraş",
    "Karabük",
    "Karaman",
    "Kars",
    "Kastamonu",
    "Kayseri",
    "Kilis",
    "Kırıkkale",
    "Kırklareli",
    "Kırşehir",
    "Kocaeli",
    "Konya",
    "Kütahya",
    "Malatya",
    "Manisa",
    "Mardin",
    "Mersin",
    "Muğla",
    "Muş",
    "Nevşehir",
    "Niğde",
    "Ordu",
    "Osmaniye",
    "Rize",
    "Sakarya",
    "Samsun",
    "Şanlıurfa",
    "Siirt",
    "Sinop",
    "Sivas",
    "Şırnak",
    "Tekirdağ",
    "Tokat",
    "Trabzon",
    "Tunceli",
    "Uşak",
    "Van",
    "Yalova",
    "Yozgat",
    "Zonguldak",
  ];
  const data = [
    // { label: "Geçirilen Kazada Yaralanma Durumu", value: "Tıbbi Operasyon" },
    // { label: "Yaralanma Durumunu Açıklayınız", value: "yaralandım" },
    { label: "Maluliyet Oranınız", value: maluliyetdurumu },
    // { label: "Kazada ki Konumunuz", value: "Yolcu" },
    { label: "Kaza Tarihi", value: kazatarihi },
    { label: "Kusur Oranınız ", value: kusurdurumu },
    { label: "Mevcut Belgelenebilir Aylık Geliriniz", value: gelir },
    { label: "Kazazedinin Adı Soyadı", value: name },
    { label: "Kazazedenin Doğum Tarihi", value: dogumyili },
    { label: "Tazminat hesabının bildirileceği telefon", value: telno },
  ];
  const history = useHistory();
  const handleSelectChange = (e) => {
    setKazaTuru(e.target.value);
  };
  const handleSelectChangeKusur = (e) => {
    setKusurDurumu(e.target.value);
  };
  const handleSelectChangeCinsiyet = (e) => {
    setCinsiyet(e.target.value);
  };
  const handleSelectChangeIl = (e) => {
    setIl(e.target.value);
  };

  const handlePhoneChange = (event) => {
    let value = event.target.value.replace(/\D/g, ""); // Sadece rakamları al

    if (value.length === 0 || value[0] !== "0") {
      value = "0" + value; // Başlangıçta 0 sabit olsun
    }

    if (value.length > 11) value = value.slice(0, 11); // Maksimum 11 hane (0XXX XXX XX XX)

    setRawNumber(value);
    setTelNo(formatPhoneNumber(value));
  };

  const formatPhoneNumber = (value) => {
    let formattedValue = "";
    if (value.length > 0)
      formattedValue = `${value[0]} (${value.slice(1, 4)}) `;
    if (value.length > 4) formattedValue += `${value.slice(4, 7)} `;
    if (value.length > 7) formattedValue += `${value.slice(7, 9)} `;
    if (value.length > 9) formattedValue += `${value.slice(9, 11)}`;
    return formattedValue;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      setRawNumber((prev) => {
        const newValue = prev.slice(0, -1);
        setTelNo(formatPhoneNumber(newValue));
        return newValue;
      });
      event.preventDefault(); // Varsayılan silme davranışını engelle
    }
  };

  const submit = async (e) => {
    setLoading(true);
    SetSuccess(false);
    // SetErrorField("")
    e.preventDefault();
    try {
      // const response = await axios.post("http://localhost:5000/yaralanma", { kazaturu, kazatarihi, kusurdurumu, maluliyetdurumu, dogumyili, cinsiyet, gelir, telno, il, name });
      const response = await axios.post(
        "https://trafikcezasorgulama.vercel.app/yaralanma",
        {
          kazaturu,
          kazatarihi,
          kusurdurumu,
          maluliyetdurumu,
          dogumyili,
          cinsiyet,
          gelir,
          telno,
          il,
          name,
        }
      );
      if (response.data.success) {
        setFinish(true);
        // history.push("/basarili");
        // SetSuccess(true)
        // setKazaTuru("");
        // setKazaTarihi("");
        // setKusurDurumu("");
        // setMaluliyetDurumu("");
        // setDogumYili("");
        // setCinsiyet("");
        // setGelir("");
        // setTelNo("");
        // setIl("");
        // setName("");
      }
    } catch (error) {
      setLoading(false);
      SetErrorField(error.response.data);
    }
  };
  const Error = (props) => {
    const message = props.ErrorType;
    return (
      <div className="my-3">
        <div className="text-xs text-red-500 font-bold">{message}</div>
      </div>
    );
  };
  const Success = () => {
    if (success === true) {
      return (
        <div className="my-2">
          <div className="text-xs text-green-500 font-bold">
            Kaydınız oluşturuldu.
          </div>
        </div>
      );
    }
  };
  if (finish === false) {
    return (
      <div>
        {/* <div className="mt-4 mb-2">
          <select
            value={kazaturu}
            onChange={handleSelectChange}
            className="shadow  px-1 text-xs border rounded w-full  text-gray-400 leading-tight focus:outline-none focus:shadow-outline h-6"
          >
            <option>Kaza Türü</option>
            <option value="Trafik Kazası">Trafik Kazası</option>
            <option value="İş Kazası">İş Kazası</option>
            <option value="Malpraktis">Malpraktis</option>
          </select>
        </div> */}
        <input
          className="shadow  border rounded w-full my-2 py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline h-6"
          id="firstName"
          type="text"
          placeholder="Kaza Tarihi"
          value={kazatarihi}
          onChange={(event) => setKazaTarihi(event.target.value)}
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
        />
        <div className="my-2">
          <select
            value={kusurdurumu}
            onChange={handleSelectChangeKusur}
            className="shadow  px-1 text-xs border rounded w-full  text-gray-400 leading-tight focus:outline-none focus:shadow-outline h-6"
          >
            <option>Kusur Durumunuz</option>
            <option value="0/8">0/8(%0)</option>
            <option value="2/8">2/8(%25)</option>
            <option value="4/8">4/8(%50)</option>
            <option value="6/8">6/8(%75)</option>
            <option value="8/8">8/8(%100)</option>
          </select>
        </div>
        <input
          className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
          id="firstName"
          type="number"
          placeholder="Maluliyet Durumu (1-99 Arası)"
          value={maluliyetdurumu}
          onChange={(event) => setMaluliyetDurumu(event.target.value)}
        />
        <input
          className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
          id="firstName"
          type="number"
          placeholder="Doğum Yılınız"
          value={dogumyili}
          onChange={(event) => setDogumYili(event.target.value)}
        />
        <div className="my-2">
          <select
            value={cinsiyet}
            onChange={handleSelectChangeCinsiyet}
            className="shadow  px-1 text-xs border rounded w-full  text-gray-400 leading-tight focus:outline-none focus:shadow-outline h-6"
          >
            <option>Cinsiyetiniz</option>
            <option value="Kadın">Kadın</option>
            <option value="Erkek">Erkek</option>
          </select>
        </div>
        <input
          className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
          id="firstName"
          type="number"
          placeholder="Geliriniz"
          value={gelir}
          onChange={(event) => setGelir(event.target.value)}
        />
        <input
          className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
          id="firstName"
          type="text"
          placeholder="Adınız ve Soyadınız"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <input
          className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6"
          id="firstName"
          type="tel"
          placeholder="Tazminat Tutarınız Bildirileceği Telefon Numarası(5xx xxx xx xx)"
          value={telno}
          onChange={handlePhoneChange}
          onKeyDown={handleKeyDown}
          maxLength={17}
        />
        <div className="my-2">
          <select
            value={il}
            onChange={handleSelectChangeIl}
            className="shadow  px-1 text-xs border rounded w-full  text-gray-400 leading-tight focus:outline-none focus:shadow-outline h-6"
          >
            <option>Yaşadığınız İl</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <Error ErrorType={errorField.ErrorMessage} />
        <Success />
        {isLoading ? (
          <div className="flex items-center justify-between mt-3">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={submit}
            >
              <span className="animate-spin inline-block mr-2">
                {/* Basit bir Tailwind spinner. Daha detaylı spinnerlar için 3. parti kütüphaneleri veya özel CSS kullanabilirsiniz. */}
                <svg
                  className="w-4 h-4 border-t-2 border-white border-solid rounded-full"
                  viewBox="0 0 24 24"
                ></svg>
              </span>
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-between mt-3">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={submit}
            >
              Hesapla
            </button>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="max-w-4xl mx-auto text-xl ">
        <div className="bg-green-100 p-4 rounded-md text-green-800 text-center mb-4 text-lg">
          Lütfen girmiş olduğunuz telefon numarasını kontrol ediniz. Aksi halde hesaplanan tazminat miktarı size bildirilemeyecektir. Tazminat
          hesaplaması tamamen ücretsiz bir hizmettir.
        </div>
        <div className="bg-green-100 p-4 rounded-md text-green-800  mb-4 sm:flex text-lg">
          <div className="sm:ml-12">Form Numaranız : 3012</div>
          <div className="sm:ml-12 sm:mt-0 mt-2 sm:flex">Yeniden form doldurmak için <a href="/tazminat-hesapla" className="ml-2 text-red-800 font-bold">tıklayınız.</a></div>
        </div>
        <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
          {data.map((item, index) => (
            <div
              key={index}
              className={`grid grid-cols-1 md:grid-cols-2 p-4 border-b ${
                index === data.length - 1 ? "" : "border-gray-200"
              }`}
            >
              <div className="font-medium text-gray-700">{item.label}</div>
              <div className="text-gray-900 mt-2 md:mt-0">{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
