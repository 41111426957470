import React , {useEffect} from "react";

const ResponsiveTable = () => {

  useEffect(()=>{

  },[])
  const data = [
    { label: "Geçirilen Kazada Yaralanma Durumu", value: "Tıbbi Operasyon" },
    // { label: "Yaralanma Durumunu Açıklayınız", value: "yaralandım" },
    { label: "Biliyorsanız % Maluliyet Oranınız", value: "Seçilen Değer: 40 (0 min / 100 maks)" },
    // { label: "Kazada ki Konumunuz", value: "Yolcu" },
    { label: "Kaza Tarihi", value: "02/02/1990" },
    { label: "Kusur Oranınız %", value: "100" },
    { label: "Mevcut Belgelenebilir Aylık Geliriniz", value: "Seçilen Değer: 200000 (0 min / 200000 maks)" },
    { label: "Kazazedinin Adı Soyadı", value: "sanane" },
    { label: "Kazazedenin Doğum Tarihi", value: "21/02/2025" },
    { label: "Tazminat hesabının bildirileceği telefon", value: "+903131313131" }
  ];

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4 text-center">Trafik Kazası Tazminat Hesaplama</h1>
      <div className="bg-green-100 p-4 rounded-md text-green-800 text-center mb-4">
        Lütfen girmiş olduğunuz telefon numarasını kontrol ediniz. Tazminat hesaplaması tamamen ücretsiz bir hizmettir.
      </div>
      <div className="border border-gray-200 rounded-lg shadow-sm overflow-hidden">
        {data.map((item, index) => (
          <div
            key={index}
            className={`grid grid-cols-1 md:grid-cols-2 p-4 border-b ${
              index === data.length - 1 ? "" : "border-gray-200"
            }`}
          >
            <div className="font-medium text-gray-700">{item.label}</div>
            <div className="text-gray-900 mt-2 md:mt-0">{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResponsiveTable;
